<template>
<iframe :src="link">

</iframe>
</template>

<script>
export default {
  name: 'IframeView',
  data: function () {
    return {
      link: ''
    }
  },
  created: function () {
    this.link = this.$store.state.ViewMenuData.menuUrl
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.link = this.$store.state.ViewMenuData.menuUrl
    }
  }
}
</script>

<style scoped lang="scss">
iframe{
    width: 100%;
    height: 100%;
    border: 0;
}
</style>
